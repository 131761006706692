import { Expose, Transform, Type } from 'class-transformer'
import { EventIdType, Event } from './event'
import { Ticket, TicketIdType } from './ticket'
import { User, UserIdType } from './user'
import { OptionInterface } from '/-/components/controls/setup'
import { enumToOptions, optionsToMap } from '/~/plugins/utils'
import { MemberRole } from '/~/models/common'
import dayjs from 'dayjs'

export type MemberIdType = string

export enum MemberStatus {
  Empty = 2,
  Active = 10,
}

export const MEMBER_ROLE_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(MemberRole, true, true)
export const MemberRoleMap = optionsToMap(MEMBER_ROLE_OPTIONS)

export class Member {
  uid!: MemberIdType

  status!: MemberStatus

  country!: string

  city!: string

  geo!: string

  @Expose({ name: 'city_id' })
  cityId!: number

  @Expose({ name: 'event_id' })
  eventId!: EventIdType

  @Expose({ name: 'ticket_id' })
  ticketId!: TicketIdType

  @Expose({ name: 'user_id' })
  userId!: UserIdType

  @Expose({ name: 'ticket_code' })
  ticketCode!: string

  @Expose({ name: 'is_visible' })
  isVisible = true

  @Expose({ name: 'is_speaker' })
  isSpeaker!: boolean

  @Expose({ name: 'is_sponsor' })
  isSponsor!: boolean

  @Expose({ name: 'is_partner' })
  isPartner!: boolean

  @Expose({ name: 'is_organizer' })
  isOrganizer!: boolean

  @Expose({ name: 'invite_sent' })
  welcomeSent!: number

  @Expose({ name: 'role_id' })
  roleId!: MemberRole

  @Expose({ name: 'role_ids' })
  @Transform(({ value }) => !value ? [] : value, { toClassOnly: true })
  roleIds!: MemberRole[]

  @Type(() => Event)
  event!: Event

  @Type(() => User)
  user!: User

  @Type(() => Ticket)
  ticket!: Ticket

  get id() {
    return this.uid
  }

  get welcomeSentDate() {
    return this.welcomeSent ? dayjs(this.welcomeSent).format('DD.MM.YYYY, HH:mm') : ''
  }

  get memberName() {
    return this.user?.fullName
  }

  get eventName() {
    return this.event?.name
  }

  get ticketName() {
    return this.ticket?.name
  }
}

export class GetUploadingStatusResponse {
  id!: number

  @Expose({ name: 'event_id' })
  eventId!: number

  @Expose({ name: 'is_processed' })
  isProcessed!: boolean

  log!: string[]
}
