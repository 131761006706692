import auth from './auth'
import core from './core'
import ticket from './ticket'
import eventLectures from './eventLectures'
import email from './email'
import program from './program'
import formErrors from './formErrors'
import user from './user'
import catalogs from './catalogs'

export default {
  auth,
  core,
  ticket,
  email,
  catalogs,
  eventLectures,
  program,
  formErrors,
  user,
}
