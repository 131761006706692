import { h, resolveComponent } from 'vue'
import { RouteNames } from './route-names'
import { EventType } from '/~/models/event'

interface RouterProps {
  params: {
    id: string,
  }
}

interface NewsRouterProps {
  params: {
    id: string,
    lang: string,
  }
}

interface MemberRouterProps extends RouterProps {
  params: {
    id: string
    memberId: string
  }
}

interface EmailsRouterProps extends RouterProps {
  params: {
    id: string
    emailId: string
  }
}

interface TicketsRouterProps extends RouterProps {
  params: {
    id: string
    ticketId?: string
  }
}

interface ShowcasesRouterProps extends RouterProps {
  params: {
    id: string
    showcaseId?: string
  }
}

interface ShowcasesRequestsRouterProps extends RouterProps {
  params: {
    id: string
    requestId: string
    showcaseId: string
  }
}

interface EventLectureRouterProps extends RouterProps {
  params: {
    id: string
    lectureId: string
  }
}

interface EventBadgesPrintRouterProps extends RouterProps {
  params: {
    id: string
    userId: string
    badgeId: string
  }
}

interface EventBadgesTemplatesRouterProps {
  params: {
    eventId: string
    badgeId?: string
  }
}

interface AnswerRouterProps extends RouterProps {
  params: {
    id: string
    answerId: string
  }
}

interface EventsRouterProps {
  params: {
    type: EventType
  }
}

interface MarketplaceRouterProps extends RouterProps {
  params: {
    id: string
    parentId?: string
  }
}

const emptyRouteComponent = {
  render: () => h(resolveComponent('router-view')),
}

export default [
  {
    path: '/',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/home/home-view.vue'),
        meta: {
          title: 'Dashboard'
        }
      }
    ]
  },
  {
    path: '/events',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'events',
        component: () => import('../views/events/events-list.vue'),
        props: () => ({
          type: EventType.Default
        }),
        meta: {
          title: 'Events'
        }
      },
      {
        path: 'create',
        name: 'events-create',
        component: () => import('../views/events/events-form.vue'),
        props: ({ params }: EventsRouterProps) => ({
          type: params.type
        }),
        meta: {
          title: 'Create Event'
        }
      },
      {
        path: ':id',
        component: () => import('../views/events/event-root.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        redirect: { name: 'event-info' },
        children: [
          {
            path: 'info',
            name: 'event-info',
            component: () => import('../views/events/event-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Event Info'
            }
          },
          {
            path: 'branding',
            name: 'event-branding',
            component: () => import('../views/events/event-branding.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Event Branding'
            }
          },
          {
            path: 'config',
            component: () => import('../views/events/config/config-root.vue'),
            children: [
              {
                path: '',
                name: 'event-config',
                component: () => import('../views/events/config/config-main.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config'
                }
              },
              {
                path: 'login',
                name: 'event-config-login',
                component: () => import('../views/events/config/config-login.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Login'
                }
              },
              {
                path: 'program',
                name: 'event-config-program',
                component: () => import('../views/events/config/config-program.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Program'
                }
              },
              {
                path: 'expo',
                name: 'event-config-expo',
                component: () => import('../views/events/config/config-expo.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Expo'
                }
              },
              {
                path: 'menu',
                name: 'event-config-menu',
                component: () => import('../views/events/config/config-menu.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Menu'
                }
              },
              {
                path: 'lectures',
                name: 'event-config-lectures',
                component: () => import('../views/events/config/config-lectures.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Lectures'
                }
              },
              {
                path: 'speakers',
                name: 'event-config-speakers',
                component: () => import('../views/events/config/config-speakers.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Speakers'
                }
              },
              {
                path: 'theme',
                name: 'event-config-theme',
                component: () => import('../views/events/config/config-theme.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Theme'
                }
              },
              {
                path: 'registration',
                name: 'event-config-registration',
                component: () => import('../views/events/config/config-registration.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Registration'
                }
              },
              {
                path: 'chat-private',
                name: 'event-config-chat-private',
                component: () => import('../views/events/config/config-chat-private.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Chat Private'
                }
              },
              {
                path: 'chat-public',
                name: 'event-config-chat-public',
                component: () => import('../views/events/config/config-chat-public.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Chat Public'
                }
              },
              {
                path: 'analytic',
                name: 'event-config-analytic',
                component: () => import('../views/events/config/config-analytic.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Analytic'
                }
              },
              {
                path: 'gcore',
                name: 'event-config-gcore',
                component: () => import('../views/events/config/config-gcore.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config GCore'
                }
              },
              {
                path: 'zoom',
                name: 'event-config-zoom',
                component: () => import('../views/events/config/config-zoom.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Zoom'
                }
              },
              {
                path: 'wnmeet',
                name: 'event-config-wnmeet',
                component: () => import('../views/events/config/config-wnmeet.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config WN Meet'
                }
              },
              {
                path: 'meeting',
                name: 'event-config-meeting',
                component: () => import('../views/events/config/config-meeting.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Meeting'
                }
              },
              {
                path: 'editor',
                name: 'event-config-editor',
                component: () => import('../views/events/config/config-editor.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Config Editor'
                }
              },
              {
                path: 'features',
                name: 'event-config-features',
                component: () => import('../views/events/config/config-features.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'Features'
                }
              },
              {
                path: 'general',
                name: 'event-config-general',
                component: () => import('../views/events/config/config-general.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
                meta: {
                  title: 'General'
                }
              },
            ]
          },
          {
            path: 'statistics',
            name: 'event-statistics',
            component: () => import('../views/events/statistics/event-statistics-root.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Event Statistics'
            }
          },
          {
            path: 'linking',
            name: 'event-linking',
            component: () => import('../views/events/event-linking.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Event Linking'
            }
          },
          {
            path: 'programs',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'event-programs',
                component: () => import('../views/events/programs/programs-list.vue'),
                props: ({ params }: RouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Event Programs'
                }
              },
              {
                path: ':programId',
                name: 'program-view',
                component: () => import('../views/events/programs/program-view.vue'),
                props: ({ params }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.programId)
                })
              },
              {
                path: ':programId/days/:dayId/streams/:streamId',
                name: 'stream-view',
                component: () => import('../views/events/programs/stream-view.vue'),
                props: ({ params }) => ({
                  programId: parseInt(params.programId),
                  dayId: parseInt(params.dayId),
                  streamId: parseInt(params.streamId)
                })
              },
              {
                path: ':programId/edit',
                name: 'program-edit',
                component: () => import('../views/events/programs/program-form.vue'),
                props: ({ params }) => ({
                  eventId: parseInt(params.id),
                  programId: parseInt(params.programId)
                })
              },
              {
                path: 'create',
                name: 'program-create',
                component: () => import('../views/events/programs/program-form.vue'),
                props: ({ params }) => ({
                  eventId: parseInt(params.id)
                })
              }
            ],
          },
          {
            path: 'lectures',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'event-lectures',
                component: () => import('../views/events/lectures/lectures-list.vue'),
                props: ({ params }: RouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Event Lectures'
                }
              },
              {
                path: ':lectureId',
                name: 'event-lecture-view',
                component: () => import('../views/events/lectures/lectures-view.vue'),
                props: ({ params }: EventLectureRouterProps) => ({
                  eventId: parseInt(params.id),
                  lectureId: parseInt(params.lectureId)
                })
              },
              {
                path: ':lectureId/edit',
                name: 'event-lecture-edit',
                component: () => import('../views/events/lectures/lectures-form.vue'),
                props: ({ params }: EventLectureRouterProps) => ({
                  eventId: parseInt(params.id),
                  lectureId: parseInt(params.lectureId)
                })
              },
              {
                path: 'create',
                name: 'event-lecture-create',
                component: () => import('../views/events/lectures/lectures-form.vue'),
                props: ({ params }: RouterProps) => ({
                  eventId: parseInt(params.id)
                })
              }
            ],
          },
          {
            path: 'emails',
            component: () => import('../views/events/emails/emails-root.vue'),
            children: [
              {
                path: '',
                name: 'event-emails',
                component: () => import('../views/events/emails/emails-list.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Emails'
                }
              },
              {
                path: 'create',
                name: 'emails-create',
                component: () => import('../views/events/emails/emails-form.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Email'
                }
              },
              {
                path: ':emailId',
                name: 'email',
                component: () => import('../views/events/emails/emails-view.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.emailId)
                }),
                meta: {
                  title: 'Email'
                }
              },
              {
                path: ':emailId/edit',
                name: 'email-edit',
                component: () => import('../views/events/emails/emails-form.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.emailId)
                }),
                meta: {
                  title: 'Email'
                }
              }
            ]
          },
          {
            path: 'members',
            component: () => import('../views/events/members/members-root.vue'),
            children: [
              {
                path: '',
                name: 'event-members',
                component: () => import('../views/events/members/members-list.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Members'
                }
              },
              {
                path: 'create',
                name: 'members-create',
                component: () => import('../views/events/members/members-form.vue'),
                props: ({ params }: EmailsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Member'
                }
              },
              {
                path: ':memberId',
                name: 'member',
                component: () => import('../views/events/members/members-view.vue'),
                props: ({ params }: MemberRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: params.memberId
                }),
                meta: {
                  title: 'Member'
                }
              },
              {
                path: ':memberId/edit',
                name: 'member-edit',
                component: () => import('../views/events/members/members-form.vue'),
                props: ({ params }: MemberRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: params.memberId
                }),
                meta: {
                  title: 'Member'
                }
              }
            ]
          },
          {
            path: 'tickets',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: RouteNames.Tickets,
                component: () => import('../views/events/tickets/tickets-list.vue'),
                props: ({ params }: TicketsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Event Tickets'
                }
              },
              {
                path: 'create',
                name: RouteNames.TicketCreate,
                component: () => import('../views/events/tickets/tickets-form.vue'),
                props: ({ params }: TicketsRouterProps) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Ticket'
                }
              },
              {
                path: ':ticketId',
                name: RouteNames.Ticket,
                component: () => import('../views/events/tickets/tickets-view.vue'),
                props: ({ params }: TicketsRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.ticketId || ''),
                }),
                meta: {
                  title: 'Event Ticket'
                }
              },
              {
                path: ':ticketId/edit',
                name: RouteNames.TicketEdit,
                component: () => import('../views/events/tickets/tickets-form.vue'),
                props: ({ params }: TicketsRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.ticketId || ''),
                }),
                meta: {
                  title: 'Event Ticket'
                }
              },
            ]
          },
          {
            path: 'event-meetings',
            component: () => import('../views/events/meeting/meeting-settings-entry.vue'),
            children: [
              {
                path: '',
                name: RouteNames.MeetingSettings,
                component: () => import('../views/events/meeting/meeting-settings.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Meeting Settings'
                }
              },
              {
                path: 'locations',
                name: RouteNames.MeetingLocations,
                component: () => import('../views/events/meeting/meeting-locations.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Meeting Locations'
                }
              },
            ]
          },
          {
            path: 'ticket-templates',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: RouteNames.TicketTemplates,
                component: () => import('../views/events/ticket-templates/ticket-templates-list.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Ticket Templates'
                }
              },
              {
                path: 'create',
                name: RouteNames.TicketTemplateCreate,
                component: () => import('../views/events/ticket-templates/ticket-templates-form.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Ticket Template'
                }
              },
              {
                path: ':ticketTemplateId',
                name: RouteNames.TicketTemplate,
                component: () => import('../views/events/ticket-templates/ticket-templates-view.vue'),
                props: ({ params }: { params: { id: string, ticketTemplateId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.ticketTemplateId || ''),
                }),
                meta: {
                  title: 'Ticket Template'
                }
              },
              {
                path: ':ticketTemplateId/edit',
                name: RouteNames.TicketTemplateEdit,
                component: () => import('../views/events/ticket-templates/ticket-templates-form.vue'),
                props: ({ params }: { params: { id: string, ticketTemplateId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.ticketTemplateId || ''),
                }),
                meta: {
                  title: 'Edit Ticket Template'
                }
              },
            ]
          },
          {
            path: 'certificate-templates',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: RouteNames.CertificateTemplates,
                component: () => import('../views/events/certificate-templates/certificate-templates-list.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Certificate Templates'
                }
              },
              {
                path: 'create',
                name: RouteNames.CertificateTemplateCreate,
                component: () => import('../views/events/certificate-templates/certificate-templates-form.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Certificate Template'
                }
              },
              {
                path: ':certificateTemplateId',
                name: RouteNames.CertificateTemplate,
                component: () => import('../views/events/certificate-templates/certificate-templates-view.vue'),
                props: ({ params }: { params: { id: string, certificateTemplateId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.certificateTemplateId || ''),
                }),
                meta: {
                  title: 'Certificate Template'
                }
              },
              {
                path: ':certificateTemplateId/edit',
                name: RouteNames.CertificateTemplateEdit,
                component: () => import('../views/events/certificate-templates/certificate-templates-form.vue'),
                props: ({ params }: { params: { id: string, certificateTemplateId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.certificateTemplateId || ''),
                }),
                meta: {
                  title: 'Edit Certificate Template'
                }
              },
            ]
          },
          {
            path: 'mailings',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: RouteNames.MailingsList,
                component: () => import('../views/events/mailings/mailings-list.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Mailings List'
                }
              },
              {
                path: 'create',
                name: RouteNames.MailingsCreate,
                component: () => import('../views/events/mailings/mailings-form.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Mailing'
                }
              },
              {
                path: ':mailingId',
                name: RouteNames.MailingsView,
                component: () => import('../views/events/mailings/mailings-view.vue'),
                props: ({ params }: { params: { id: string, mailingId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.mailingId || ''),
                }),
                meta: {
                  title: 'Mailing'
                }
              },
            ]
          },
          {
            path: 'promo-codes',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: RouteNames.PromoCodes,
                component: () => import('../views/events/promo-codes/promo-codes-list.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Promo Codes'
                }
              },
              {
                path: 'create',
                name: RouteNames.PromoCodeCreate,
                component: () => import('../views/events/promo-codes/promo-codes-form.vue'),
                props: ({ params }: { params: { id: string } }) => ({
                  eventId: parseInt(params.id)
                }),
                meta: {
                  title: 'Create Promo Code'
                }
              },
              {
                path: ':promoCodeId',
                name: RouteNames.PromoCode,
                component: () => import('../views/events/promo-codes/promo-codes-view.vue'),
                props: ({ params }: { params: { id: string, promoCodeId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.promoCodeId || ''),
                }),
                meta: {
                  title: 'Promo Code'
                }
              },
              {
                path: ':promoCodeId/edit',
                name: RouteNames.PromoCodeEdit,
                component: () => import('../views/events/promo-codes/promo-codes-form.vue'),
                props: ({ params }: { params: { id: string, promoCodeId: string } }) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.promoCodeId || ''),
                }),
                meta: {
                  title: 'Edit Promo Code'
                }
              },
            ]
          },
          {
            path: 'showcases',
            component: () => import('../views/events/showcases/showcases-root.vue'),
            children: [
              {
                path: 'create',
                name: 'event-showcase-create',
                component: () => import('../views/events/showcases/showcases-form.vue'),
                props: ({ params }: ShowcasesRouterProps) => ({
                  eventId: parseInt(params.id),
                }),
                meta: {
                  title: 'Create Showcase'
                }
              },
              {
                path: ':showcaseId/edit',
                name: 'event-showcase-edit',
                component: () => import('../views/events/showcases/showcases-form.vue'),
                props: ({ params }: ShowcasesRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.showcaseId)
                }),
                meta: {
                  title: 'Edit Showcase'
                }
              },
              {
                path: ':showcaseId',
                name: 'event-showcase',
                component: () => import('../views/events/showcases/showcases-view.vue'),
                props: ({ params }: ShowcasesRouterProps) => ({
                  eventId: parseInt(params.id),
                  id: parseInt(params.showcaseId)
                }),
                meta: {
                  title: 'Event Showcase'
                },
              },
              {
                path: '',
                name: 'event-showcases',
                component: () => import('../views/events/showcases/showcases-list.vue'),
                props: ({ params }: ShowcasesRouterProps) => ({
                  eventId: parseInt(params.id),
                }),
                meta: {
                  title: 'Event Showcases'
                }
              },
              {
                path: ':showcaseId/requests/:requestId/edit',
                name: 'showcase-request-edit',
                component: () => import('../views/events/showcases/showcases-requests-form.vue'),
                props: ({ params }: ShowcasesRequestsRouterProps) => ({
                  eventId: parseInt(params.id),
                  showcaseId: parseInt(params.showcaseId),
                  requestId: parseInt(params.requestId)
                }),
                meta: {
                  title: 'Edit Showcase Request'
                }
              },
              {
                path: ':showcaseId/requests/create',
                name: 'showcase-request-create',
                component: () => import('../views/events/showcases/showcases-requests-form.vue'),
                props: ({ params }: ShowcasesRequestsRouterProps) => ({
                  eventId: parseInt(params.id),
                  showcaseId: parseInt(params.showcaseId),
                }),
                meta: {
                  title: 'Create Showcase Request'
                }
              },
              {
                path: ':showcaseId/requests/:requestId',
                name: 'showcase-request',
                component: () => import('../views/events/showcases/showcases-requests-view.vue'),
                props: ({ params }: ShowcasesRequestsRouterProps) => ({
                  eventId: parseInt(params.id),
                  showcaseId: parseInt(params.showcaseId),
                  requestId: parseInt(params.requestId)
                }),
                meta: {
                  title: 'Showcase Request'
                }
              },
              {
                path: ':showcaseId/requests',
                name: 'showcase-request-list',
                props: ({ params }: ShowcasesRequestsRouterProps) => ({
                  eventId: parseInt(params.id),
                  showcaseId: parseInt(params.showcaseId),
                }),
                redirect: { name: 'event-showcase' },
              },
            ]
          },
          {
            path: 'map-admin',
            name: 'event-map-admin',
            component: () => import('../views/events/map-admin/map-admin-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Event Map Admin'
            }
          },
          {
            path: 'badges',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                component: () => import('../views/events/badges/badges-main.vue'),
                children: [
                  {
                    path: '',
                    name: RouteNames.Badges,
                    component: () => import('../views/events/badges/main/badges-main-users-list.vue'),
                    meta: {
                      title: 'Members'
                    }
                  },
                  {
                    path: ':userId/:badgeId',
                    name: RouteNames.BadgesPrint,
                    component: () => import('../views/events/badges/main/badges-main-users-list.vue'),
                    props: ({ params }: EventBadgesPrintRouterProps) => ({
                      userId: parseInt(params.userId),
                      badgeId: parseInt(params.badgeId),
                    }),
                    meta: {
                      title: 'Print'
                    }
                  },
                  {
                    path: 'templates',
                    name: RouteNames.BadgesTemplates,
                    component: () => import('../views/events/badges/main/badges-main-templates-list.vue'),
                    meta: {
                      title: 'Templates'
                    },
                  },
                ]
              },
            ],
          },
        ]
      },
      {
        path: ':id/edit',
        name: 'event-edit',
        component: () => import('../views/events/events-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Event'
        }
      }
    ]
  },
  {
    path: '/events/:eventId/badges/templates',
    component: () => import('../layouts/badges-template-editor.vue'),
    props: ({ params }: EventBadgesTemplatesRouterProps) => ({
      eventId: parseInt(params.eventId)
    }),
    children: [
      {
        path: 'create',
        name: RouteNames.BadgesTemplateCreate,
        component: () => import('../views/events/badges/badges-template-editor.vue'),
        props: ({ params }: EventBadgesTemplatesRouterProps) => ({
          eventId: parseInt(params.eventId)
        }),
        meta: {
          title: 'Create Template',
        }
      },
      {
        path: ':badgeId',
        name: RouteNames.BadgesTemplate,
        component: () => import('../views/events/badges/badges-template-editor.vue'),
        props: ({ params }: EventBadgesTemplatesRouterProps) => ({
          eventId: parseInt(params.eventId),
          id: params.badgeId && parseInt(params.badgeId)
        }),
        meta: {
          title: 'Template',
        }
      },
    ],
  },
  {
    path: '/courses',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'courses',
        component: () => import('../views/events/events-list.vue'),
        props: () => ({
          type: EventType.Course
        }),
        meta: {
          title: 'Courses'
        }
      },
    ],
  },
  {
    path: '/users',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('../views/users/users-list.vue'),
        meta: {
          title: 'Users'
        }
      },
      {
        path: 'create',
        name: 'users-create',
        component: () => import('../views/users/users-form.vue'),
        meta: {
          title: 'Create User'
        }
      },
      {
        path: ':id',
        name: 'user',
        component: () => import('../views/users/users-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'User'
        }
      },
      {
        path: ':id/edit',
        name: 'user-edit',
        component: () => import('../views/users/users-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'User'
        }
      }
    ]
  },
  {
    path: '/companies',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'companies',
        component: () => import('../views/companies/companies-list.vue'),
        meta: {
          title: 'Companies'
        }
      },
      {
        path: 'create',
        name: 'companies-create',
        component: () => import('../views/companies/companies-form.vue'),
        meta: {
          title: 'Create Company'
        }
      },
      {
        path: ':id',
        name: 'company',
        component: () => import('../views/companies/companies-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Company'
        }
      },
      {
        path: ':id/edit',
        name: 'company-edit',
        component: () => import('../views/companies/companies-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Company'
        }
      }
    ]
  },
  {
    path: '/tilda-pages',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: RouteNames.TildaPages,
        component: () => import('../views/tilda-pages/tilda-pages-list.vue'),
        meta: {
          title: 'Tilda Pages'
        }
      },
      {
        path: 'create',
        name: RouteNames.TildaPageCreate,
        component: () => import('../views/tilda-pages/tilda-pages-form.vue'),
        meta: {
          title: 'Create Tilda Page'
        }
      },
      {
        path: ':id',
        name: RouteNames.TildaPage,
        component: () => import('../views/tilda-pages/tilda-pages-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Tilda Page'
        }
      },
      {
        path: ':id/edit',
        name: RouteNames.TildaPageEdit,
        component: () => import('../views/tilda-pages/tilda-pages-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Tilda Page'
        }
      }
    ]
  },
  {
    path: '/publishers',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'publishers',
        component: () => import('../views/publishers/publishers-list.vue'),
        meta: {
          title: 'Publishers'
        }
      },
      {
        path: ':id',
        name: 'publisher-view',
        component: () => import('../views/publishers/publisher-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
      },
      {
        path: ':id/edit',
        name: 'publisher-edit',
        component: () => import('../views/publishers/publisher-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
      },
      {
        path: 'create',
        name: 'publisher-create',
        component: () => import('../views/publishers/publisher-form.vue'),
      }
    ]
  },
  {
    path: '/lecture-archives',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'lecture-archives',
        component: () => import('../views/lecture-archives/lecture-archives-list.vue'),
        meta: {
          title: 'Lecture Archive'
        }
      },
      {
        path: 'create',
        name: 'lecture-create',
        component: () => import('../views/lecture-archives/lecture-archives-form.vue'),
        meta: {
          title: 'Create Lecture'
        }
      },
      {
        path: ':id',
        name: 'lecture',
        component: () => import('../views/lecture-archives/lecture-archives-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Lecture'
        }
      },
      {
        path: ':id/edit',
        name: 'lecture-edit',
        component: () => import('../views/lecture-archives/lecture-archives-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Lecture'
        }
      },
    ]
  },
  {
    path: '/wnmeet-videos',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: RouteNames.WNMeetVideos,
        component: () => import('../views/wnmeet-videos/wnmeet-videos-list.vue'),
        meta: {
          title: 'WN Meet Records'
        }
      },
      {
        path: ':id',
        name: RouteNames.WNMeetVideo,
        component: () => import('../views/wnmeet-videos/wnmeet-videos-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'WN Meet Record'
        }
      },
    ]
  },
  {
    path: '/programs',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'programs',
        component: () => import('../views/programs/programs-root.vue'),
        redirect: { name: 'program-users' },
        children: [
          {
            path: 'templates',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'program-templates',
                component: () => import('../views/programs/program-templates/program-templates-list.vue')
              },
              {
                path: 'create',
                name: 'create-program-template',
                component: () => import('../views/programs/program-templates/program-template-form.vue')
              },
              {
                path: ':id',
                name: 'program-template-view',
                component: () => import('../views/programs/program-templates/program-template-view.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              },
              {
                path: ':id/edit',
                name: 'edit-program-template',
                component: () => import('../views/programs/program-templates/program-template-form.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              }
            ]
          },
          {
            path: 'users',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'program-users',
                component: () => import('../views/programs/program-users/program-users-list.vue')
              },
              {
                path: 'create',
                name: 'create-program-user',
                component: () => import('../views/programs/program-users/program-user-form.vue')
              },
              {
                path: ':id/edit',
                name: 'edit-program-user',
                component: () => import('../views/programs/program-users/program-user-form.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              },
              {
                path: ':id',
                name: 'program-user-view',
                component: () => import('../views/programs/program-users/program-user-view.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              }
            ]
          },
          {
            path: 'companies',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'program-companies',
                component: () => import('../views/programs/program-companies/program-companies-list.vue')
              },
              {
                path: ':id',
                name: 'program-company-view',
                component: () => import('../views/programs/program-companies/program-company-view.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              },
              {
                path: ':id/edit',
                name: 'edit-program-company',
                component: () => import('../views/programs/program-companies/program-company-form.vue'),
                props: ({ params }) => ({
                  id: parseInt(params.id)
                })
              },
              {
                path: 'create',
                name: 'create-program-company',
                component: () => import('../views/programs/program-companies/program-company-form.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    component: emptyRouteComponent,
    redirect: { name: 'auth-login' },
    meta: {
      auth: true
    },
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('../views/auth/auth-login/auth-login-entry.vue')
      }
    ]
  },
  {
    path: '/orders',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'orders-check-role',
        component: () => import('../views/orders/orders-check-role.vue'),
        children: [
          {
            path: '',
            name: RouteNames.Orders,
            component: () => import('../views/orders/orders-list.vue'),
            meta: {
              title: 'Orders'
            }
          },
          {
            path: ':id',
            name: RouteNames.Order,
            component: () => import('../views/orders/orders-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: Number(params.id)
            }),
            meta: {
              title: 'Order'
            }
          },
          {
            path: ':id/log',
            name: RouteNames.OrderLog,
            component: () => import('../views/orders/orders-view-log.vue'),
            props: ({ params }: RouterProps) => ({
              id: Number(params.id)
            }),
            meta: {
              title: 'Order Log'
            }
          },
          {
            path: 'create',
            name: RouteNames.OrderCreate,
            component: () => import('../views/orders/orders-form.vue'),
            meta: {
              title: 'Create Order'
            }
          },
          {
            path: ':id/edit',
            name: RouteNames.OrderEdit,
            component: () => import('../views/orders/orders-form.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Edit Order'
            }
          }
        ]
      },
    ]

  },
  {
    path: '/news',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('../views/news/news-list.vue'),
        meta: {
          title: 'News'
        }
      },
      {
        path: 'create',
        name: 'news-item-create',
        component: () => import('../views/news/news-form.vue'),
        meta: {
          title: 'Create News Item'
        }
      },
      {
        path: ':id/:lang',
        name: 'news-item',
        component: () => import('../views/news/news-view.vue'),
        props: ({ params }: NewsRouterProps) => ({
          id: parseInt(params.id),
          lang: params.lang
        }),
        meta: {
          title: 'News Item'
        }
      },
      {
        path: ':id/:lang/edit',
        name: 'news-item-edit',
        component: () => import('../views/news/news-form.vue'),
        props: ({ params }: NewsRouterProps) => ({
          id: parseInt(params.id),
          lang: params.lang
        }),
        meta: {
          title: 'Edit News Item'
        }
      },
    ],
  },
  {
    path: '/categories',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'categories',
        component: () => import('../views/categories/categories-list.vue'),
        meta: {
          title: 'Categories'
        }
      },
      {
        path: 'create',
        name: 'categories-create',
        component: () => import('../views/categories/categories-form.vue'),
        meta: {
          title: 'Create Category'
        }
      },
      {
        path: ':id',
        name: 'categories-item',
        component: () => import('../views/categories/categories-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Category'
        }
      },
      {
        path: ':id/edit',
        name: 'categories-edit',
        component: () => import('../views/categories/categories-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Category'
        }
      },
    ]
  },
  {
    path: '/chat',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'chat',
        component: () => import('../views/chat/channels-list.vue'),
        meta: {
          title: 'Chat'
        }
      },
      {
        path: 'create',
        name: 'chat-channel-create',
        component: () => import('../views/chat/channels-form.vue'),
        meta: {
          title: 'Create Open Channel'
        }
      },
      {
        path: ':id',
        name: 'channels-view',
        component: () => import('../views/chat/channels-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Channel'
        }
      },
      {
        path: ':id/edit',
        name: 'channels-edit',
        component: () => import('../views/chat/channels-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Edit Channel'
        }
      },
    ],
  },
  {
    path: '/promo',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'promo',
        component: () => import('../views/promo/promo-list.vue'),
        meta: {
          title: 'Promo Messages'
        }
      },
      {
        path: 'create',
        name: 'promo-create',
        component: () => import('../views/promo/promo-form.vue'),
        meta: {
          title: 'Create Promo'
        }
      },
      {
        path: ':id',
        name: 'promo-view',
        component: () => import('../views/promo/promo-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Promo'
        }
      },
      {
        path: ':id/edit',
        name: 'promo-edit',
        component: () => import('../views/promo/promo-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Promo'
        }
      },
    ],
  },
  {
    path: '/push',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'push-list',
        component: () => import('../views/push/push-list.vue'),
        meta: {
          title: 'Custom Push Notifications'
        }
      },
      {
        path: 'create',
        name: 'push-create',
        component: () => import('../views/push/push-form.vue'),
        meta: {
          title: 'Create Custom Push Notification'
        }
      },
      {
        path: ':id',
        name: 'push-view',
        component: () => import('../views/push/push-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Custom Push Notification'
        }
      },
      {
        path: ':id/logs',
        name: 'push-logs',
        component: () => import('../views/push/push-logs.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Custom Push Notification Logs'
        }
      },
      {
        path: ':id/edit',
        name: 'push-edit',
        component: () => import('../views/push/push-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Custom Push Notification'
        }
      },
    ],
  },
  {
    path: '/chat-reports',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'chat-reports',
        component: () => import('../views/chat/reports-list.vue'),
        meta: {
          title: 'Reports List'
        }
      },
    ],
  },
  {
    path: '/chat-bans',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'chat-bans',
        component: () => import('../views/chat/bans-list.vue'),
        meta: {
          title: 'Bans List'
        }
      },
    ],
  },
  {
    path: '/questions',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'questions',
        component: () => import('../views/questions/questions-list.vue'),
        meta: {
          title: 'Questions'
        }
      },
      {
        path: 'create',
        name: 'questions-create',
        component: () => import('../views/questions/questions-form.vue'),
        meta: {
          title: 'Create Question'
        }
      },
      {
        path: ':id',
        name: 'questions-item',
        component: () => import('../views/questions/questions-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Question'
        }
      },
      {
        path: ':id/edit',
        name: 'questions-edit',
        component: () => import('../views/questions/questions-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Question'
        }
      },
      {
        path: 'unapproved-answers',
        name: 'unapproved-answers',
        component: () => import('../views/questions/unapproved-answers-list.vue'),
        meta: {
          title: 'Unapproved Answers'
        }
      },
      {
        path: ':id/answers/create',
        name: 'answers-create',
        component: () => import('../views/questions/answers-form.vue'),
        props: ({ params }: AnswerRouterProps) => ({
          questionId: parseInt(params.id)
        }),
        meta: {
          title: 'Create Answer'
        }
      },
      {
        path: ':id/answers/:answerId',
        name: 'answers-item',
        component: () => import('../views/questions/answers-view.vue'),
        props: ({ params }: AnswerRouterProps) => ({
          questionId: parseInt(params.id),
          id: parseInt(params.answerId as string)
        }),
        meta: {
          title: 'Answer'
        }
      },
      {
        path: ':id/answers/:answerId/edit',
        name: 'answers-edit',
        component: () => import('../views/questions/answers-form.vue'),
        props: ({ params }: AnswerRouterProps) => ({
          questionId: parseInt(params.id),
          id: parseInt(params.answerId as string)
        }),
        meta: {
          title: 'Edit Answer'
        }
      },
    ],
  },
  {
    path: '/email-templates',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'email-templates',
        component: () => import('../views/email-templates/email-templates-list.vue'),
        meta: {
          title: 'Email Templates'
        }
      },
      {
        path: 'create',
        name: 'email-templates-create',
        component: () => import('../views/email-templates/email-templates-form.vue'),
        meta: {
          title: 'Create Email Template'
        }
      },
      {
        path: ':templateId',
        name: 'email-templates-view',
        component: () => import('../views/email-templates/email-templates-view.vue'),
        props: ({ params }: any) => ({
          id: parseInt(params.templateId)
        }),
        meta: {
          title: 'View Email Template'
        }
      },
      {
        path: ':templateId/edit',
        name: 'email-templates-edit',
        component: () => import('../views/email-templates/email-templates-form.vue'),
        props: ({ params }: any) => ({
          id: parseInt(params.templateId)
        }),
        meta: {
          title: 'Edit Email Template'
        }
      }
    ]
  },
  {
    path: '/subscriptions',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'subscriptions',
        component: () => import('../views/subscriptions/subscriptions-list.vue'),
        meta: {
          title: 'Subscriptions'
        }
      },
      {
        path: 'create',
        name: 'subscriptions-create',
        component: () => import('../views/subscriptions/subscriptions-form.vue'),
        meta: {
          title: 'Create Subscription'
        }
      },
      {
        path: ':id',
        name: 'subscriptions-item',
        component: () => import('../views/subscriptions/subscriptions-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Subscription'
        }
      },
      {
        path: ':id/edit',
        name: 'subscriptions-edit',
        component: () => import('../views/subscriptions/subscriptions-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Subscription'
        }
      },
      {
        path: 'plans',
        name: 'plans',
        component: () => import('../views/subscriptions/plans-list.vue'),
        meta: {
          title: 'Plans'
        }
      },
      {
        path: 'plans/create',
        name: 'plans-create',
        component: () => import('../views/subscriptions/plans-form.vue'),
        meta: {
          title: 'Create Plan'
        }
      },
      {
        path: 'plans/:id',
        name: 'plans-item',
        component: () => import('../views/subscriptions/plans-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Answer'
        }
      },
      {
        path: 'plans/:id/edit',
        name: 'plans-edit',
        component: () => import('../views/subscriptions/plans-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Plan'
        }
      },
    ],
  },
  {
    path: '/admins',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admins/admins-entry.vue'),
        children: [
          {
            path: '',
            name: 'admins',
            component: () => import('../views/admins/admins-list.vue'),
            meta: {
              title: 'Admins'
            },
          },
          {
            path: 'create',
            name: 'admin-create',
            component: () => import('../views/admins/admins-form.vue'),
            meta: {
              title: 'Create Admin'
            }
          },
          {
            path: ':id',
            name: 'admin',
            component: () => import('../views/admins/admins-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Admin'
            }
          },
          {
            path: ':id/edit',
            name: 'admin-edit',
            component: () => import('../views/admins/admins-form.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
            meta: {
              title: 'Edit Admin'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/custom-pages',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'custom-pages',
        component: () => import('../views/custom-pages/custom-pages-list.vue'),
        meta: {
          title: 'Custom Pages'
        }
      },
      {
        path: 'create',
        name: 'custom-pages-create',
        component: () => import('../views/custom-pages/custom-pages-form.vue'),
        meta: {
          title: 'Create Custom Page'
        }
      },
      {
        path: ':id',
        name: 'custom-pages-item',
        component: () => import('../views/custom-pages/custom-pages-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Custom Page'
        }
      },
      {
        path: ':id/edit',
        name: 'custom-pages-edit',
        component: () => import('../views/custom-pages/custom-pages-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Edit Custom Page'
        }
      }
    ]
  },
  {
    path: '/form-requests',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'form-requests',
        component: () => import('../views/form-requests/form-requests-list.vue'),
        meta: {
          title: 'Form Requests'
        }
      },
      {
        path: ':id',
        name: 'form-requests-item',
        component: () => import('../views/form-requests/form-requests-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Form Request'
        }
      },
    ]
  },
  {
    path: '/marketplace',
    component: () => import('../layouts/main.vue'),
    children: [
      {
        path: '',
        name: 'marketplace',
        component: () => import('../views/marketplace/marketplace-root.vue'),
        redirect: { name: 'marketplace-groups' },
        children: [
          {
            path: 'groups',
            name: 'marketplace-groups',
            component: () => import('../views/marketplace/groups/groups.vue'),
            meta: {
              title: 'Groups'
            },
            children: [
              {
                path: 'create',
                name: 'marketplace-groups-create',
                component: () => import('../views/marketplace/groups/groups-form-modal.vue'),
                props: ({ params }: MarketplaceRouterProps) => ({
                  parentId: params.parentId ? parseInt(params.parentId) : undefined
                }),
              },
              {
                path: ':id',
                name: 'marketplace-groups-view',
                component: () => import('../views/marketplace/groups/groups-view-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              },
              {
                path: ':id/edit',
                name: 'marketplace-groups-edit',
                component: () => import('../views/marketplace/groups/groups-form-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              }
            ]
          },
          {
            path: 'properties',
            name: 'marketplace-properties',
            component: () => import('../views/marketplace/properties/properties.vue'),
            meta: {
              title: 'Properties'
            },
            children: [
              {
                path: 'create',
                name: 'marketplace-properties-create',
                component: () => import('../views/marketplace/properties/properties-form-modal.vue'),
                props: ({ params }: MarketplaceRouterProps) => ({
                  parentId: params.parentId ? parseInt(params.parentId) : undefined
                }),
              },
              {
                path: ':id',
                name: 'marketplace-properties-view',
                component: () => import('../views/marketplace/properties/properties-view-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              },
              {
                path: ':id/edit',
                name: 'marketplace-properties-edit',
                component: () => import('../views/marketplace/properties/properties-form-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              }
            ]
          },
          {
            path: 'categories',
            name: 'marketplace-categories',
            component: () => import('../views/marketplace/categories/categories.vue'),
            meta: {
              title: 'Categories'
            },
            children: [
              {
                path: 'create',
                name: 'marketplace-categories-create',
                component: () => import('../views/marketplace/categories/categories-form-modal.vue'),
                props: ({ params }: MarketplaceRouterProps) => ({
                  parentId: params.parentId ? parseInt(params.parentId) : undefined
                }),
              },
              {
                path: ':id',
                name: 'marketplace-categories-view',
                component: () => import('../views/marketplace/categories/categories-view-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              },
              {
                path: ':id/edit',
                name: 'marketplace-categories-edit',
                component: () => import('../views/marketplace/categories/categories-form-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              }
            ]
          },
          {
            path: 'products',
            name: 'marketplace-products',
            component: () => import('../views/marketplace/products/products.vue'),
            meta: {
              title: 'Products'
            },
            children: [
              {
                path: 'create',
                name: 'marketplace-products-create',
                component: () => import('../views/marketplace/products/products-form-modal.vue'),
                props: ({ params }: MarketplaceRouterProps) => ({
                  parentId: params.parentId ? parseInt(params.parentId) : undefined
                }),
              },
              {
                path: ':id',
                name: 'marketplace-products-view',
                component: () => import('../views/marketplace/products/products-view-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              },
              {
                path: ':id/edit',
                name: 'marketplace-products-edit',
                component: () => import('../views/marketplace/products/products-form-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              },
              {
                path: ':id/fill',
                name: 'marketplace-products-fill',
                component: () => import('../views/marketplace/products/products-fill-form-modal.vue'),
                props: ({ params }: RouterProps) => ({
                  id: parseInt(params.id)
                }),
              }
            ]
          },
          {
            path: 'requests',
            name: 'marketplace-requests',
            component: () => import('../views/marketplace/form-requests/form-requests-list.vue'),
            meta: {
              title: 'Orders'
            },
          },
          {
            path: 'requests/:id',
            name: 'marketplace-form-requests-view',
            component: () => import('../views/marketplace/form-requests/form-requests-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id)
            }),
          },
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('../views/not-found.vue')
  }
]
