import dayjs from 'dayjs'

export const parseJsonClassField = ({ value }: any) => {
  if (!value) {
    return ''
  }

  try {
    const data = JSON.parse(value)

    return typeof data === 'object' ? data : String(data)
  } catch (_error) {
    return value
  }
}

export const stringifyJsonClassField = ({ value }: any) => {
  return typeof value === 'string' ? value : JSON.stringify(value)
}

export const formatToInputDateClassField = ({ value }: any): string | null => {
  return (value && dayjs(value).utc().format('YYYY-MM-DDTHH:mm:ss')) || null
}

export const formatToTzDateClassField = ({ value }: any): string | null => {
  return (value && dayjs(value).utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]')) || null
}
