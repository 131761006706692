import { Option, autocompleteToOption } from '/~/models/autocomplete'
import { Api } from '/-/plugins/api'

const state = {
  detectedGeo: null as Geo | null
}

const resource = 'gateway'

async function getDetectedGeo(): Promise<Geo> {
  const { detectGeo } = useGeo()

  if (!state.detectedGeo) {
    state.detectedGeo = await detectGeo()
  }
  return state.detectedGeo
}

export interface Geo {
  city: Option
  country: Option
}

async function detectGeo(): Promise<Geo> {
  const { data } = await Api.fetch({
    resource,
    url: '/geo/detect',
    method: 'POST'
  })

  return {
    city: autocompleteToOption(data?.city),
    country: autocompleteToOption(data?.country)
  }
}

async function getCountriesAutocomplete(query?: string): Promise<Option[]> {
  const { data } = await Api.fetch({
    resource,
    url: '/geo/countries/autocomplete',
    params: {
      query
    }
  })

  return data.map(autocompleteToOption)
}

async function getCountriesAll(): Promise<Option[]> {
  const { data } = await Api.fetch({
    resource,
    url: '/geo/countries'
  })

  return data.map(autocompleteToOption)
}

async function getCountryById(id: string): Promise<Option> {
  const { data } = await Api.fetch({
    resource,
    url: `/geo/countries/${id}`
  })

  return autocompleteToOption(data)
}

async function getCitiesAutocomplete(country: string, query: string): Promise<Option[]> {
  const { data } = await Api.fetch({
    resource,
    url: '/geo/cities/autocomplete',
    params: { country, query }
  })

  return data.map(autocompleteToOption)
}

async function getCityById(id: number): Promise<Option> {
  const { data } = await Api.fetch({
    resource,
    url: `/geo/cities/${id}`
  })

  return autocompleteToOption(data)
}

export function useGeo() {
  return {
    getDetectedGeo,
    detectGeo,
    getCountriesAutocomplete,
    getCountryById,
    getCitiesAutocomplete,
    getCityById,
    getCountriesAll,
  }
}
