<template>
  <BaseImageInput
    ref="fileInput"
    :loading="isLoading"
    :max-size="1"
    :preview="props.modelValue"
    use-cropper
    :accept="[
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/svg+xml',
    ]"
    @change="onFileChange"
    @error:size="onSizeError" />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useNotifications } from '/~/state/notifications'
import { useError } from '/~/plugins/error'
import BaseImageInput from '/~/components/controls/editable-image-input.vue'
import { useMedia } from '/~/state/media'

const { processError } = useError()

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])
const { pushNotification } = useNotifications()

const isLoading = ref(false)

function onSizeError() {
  pushNotification({
    title: 'File should be less than 1 Mb',
    theme: 'destructive',
  })
}
async function onFileChange(file: File) {
  const { uploadMedia } = useMedia()

  try {
    isLoading.value = true

    const data = await uploadMedia({ media: file, category: 'image' })

    emit('update:modelValue', data.url)

    pushNotification({
      title: 'Image was successfully uploaded',
    })
  } catch (error: any) {
    pushNotification({
      title: 'Error on image upload',
      theme: 'destructive',
    })
    processError(error, false)
  } finally {
    isLoading.value = false
  }
}
</script>
