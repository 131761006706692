import 'reflect-metadata'
import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import icons from '/-/plugins/icons'
import directives from '/-/directives'
import components from '/-/components'
import auth, { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import api from '/-/plugins/api'
import locale from '/-/plugins/locale'
import colorPicker from '/-/plugins/color-picker'
import customIcons from './icons'
import languages from './languages'
import veeValidate from './plugins/vee-validate'
import './index.scss'
import * as Sentry from '@sentry/vue'
import dayjs from '/~/plugins/dayjs'
import marked from '/~/plugins/marked'
import naiveUi from './plugins/naive-ui'
import emailEditor from './plugins/email-editor'

const app = createApp(App)

async function refreshToken() {
  const { auth, refresh, logout } = useAuth()
  const { saveToken, removeToken } = useAuthToken()

  if (auth.value) {
    try {
      const token = await refresh()

      await saveToken(token)
    } catch (error) {
      await logout()
      removeToken()
      window.location.reload()
    }
  }
}

async function setAuth() {
  const { setToken } = useAuth()
  const { getToken } = useAuthToken()

  const token = await getToken()

  if (token) {
    setToken(token)
  }
}

const dsn = import.meta.env.VITE_SENTRY_DSN as string

dsn && Sentry.init({
  app,
  logErrors: true,
  dsn,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'fetch' && hint) {
      const { input } = hint
      const [_url, request] = input || []
      const { body } = request || {}

      if (body && breadcrumb.data) {
        breadcrumb.data.body = body
      }
    }
    return breadcrumb
  },
  // beforeSend(event: any, hint: any) {
  //   console.log('on before send', event, hint)

  //   return null
  // },
})

app.use(icons, customIcons)
app.use(directives)
app.use(components)
app.use(naiveUi)
app.use(emailEditor)
app.use(colorPicker)
app.use(veeValidate)
app.use(locale, languages)
app.use(dayjs)
app.use(marked)

app.use(auth, {
  loginRoute: 'auth-login',
  router
})

app.use(api, {
  default: 'gateway-admin',
  resources: [
    {
      name: 'gateway',
      baseUrl: import.meta.env.VITE_GATEWAY_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
      refreshToken
    },
    {
      name: 'gateway-admin',
      baseUrl: import.meta.env.VITE_GATEWAY_ADMIN_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
      refreshToken
    },
    {
      name: 'gateway-oauth',
      baseUrl: import.meta.env.VITE_GATEWAY_API,
      async headers() {
        const { auth } = useAuth()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
        }
      },
    },
  ],
})

setAuth().then(async () => {
  app.use(router)
  app.mount('#app')
})
