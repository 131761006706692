import { OptionInterface } from '/-/components/controls/setup'
import { enumToOptions, optionsToMap } from '/~/plugins/utils'

export enum LocaleAbbr {
  EN = 'en',
  RU = 'ru',
  ZH = 'zh',
  ES = 'es',
  FR = 'fr',
  PT = 'pt',
  TR = 'tr',
}

export const LOCALE_ABBR_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(LocaleAbbr, false)
export const LocaleAbbrMap = optionsToMap(LOCALE_ABBR_OPTIONS)
