import { enumToOptions, optionsToMap } from '/~/plugins/utils'
import { OptionInterface } from '/-/components/controls/setup'
export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB'
}

export const CurrencySign = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.RUB]: '₽',
}

export const CURRENCY_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(Currency, false)
export const CurrencyMap = optionsToMap(CURRENCY_OPTIONS)
