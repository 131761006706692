export default function getImageExtension(type: string) {
  switch (type) {
  case 'image/jpeg':
  case 'image/jpg':
    return '.jpg'
  case 'image/png':
    return '.png'
  case 'image/gif':
    return '.gif'
  case 'image/svg+xml':
    return '.svg'
  default:
    return '.jpg'
  }
}
