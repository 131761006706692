<template>
  <div class="flex items-center gap-2">
    <input
      type="color"
      class="h-10 w-10"
      :value="modelValue"
      @input="updateValue">
    <input
      type="text"
      class="border border-gray-200 h-8 px-4"
      :value="modelValue"
      placeholder="#000000"
      @input="updateValue">
  </div>
</template>

<script setup lang="ts">
defineProps({
  modelValue: {
    type: String,
    default: '#000000',
  },
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  const value = target.value

  // validation of HEX color
  if (value.startsWith('#') && (value.length === 7 || value.length === 4)) {
    emit('update:modelValue', value)
  } else if (!value.startsWith('#') && value.length === 7) { // validation of color from color picket
    emit('update:modelValue', value)
  }
}
</script>
