import marked from 'marked'

const embedYoutube = {
  name: 'embedYoutube',
  level: 'block',
  start(src) { return src.match(/^:\[(.*)]/)?.index },
  tokenizer(src) {
    const rx1 = /^:\[(.*)]/
    const rule = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const matchMedia = src.match(rx1)
    const match = matchMedia && matchMedia[1].match(rule)

    if (match) {
      return {
        type: 'embedYoutube',
        raw: matchMedia[0],
        text: match[7].trim(),
        tokens: matchMedia[0].trim()
      }
    }
  },
  renderer(token) {
    return `<div class="w-full relative rounded-md overflow-hidden shadow-lg" style="padding-bottom: 56%;"><iframe class="w-full h-full absolute top-0 left-0" type="text/html" src="https://www.youtube.com/embed/${token.text}" frameborder="0"></iframe></div>`
  }
}

export default {
  install: () => {
    marked.use({ extensions: [embedYoutube]})
  }
}

// https://gist.github.com/tunnckoCore/9374172
