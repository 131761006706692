export class Autocomplete {
  id!: string | number
  name!: string
}

export class Option {
  label!: string
  value!: string | number
}

export function autocompleteToOption(data: Autocomplete): Option {
  return {
    value: data?.id,
    label: data?.name,
  }
}
