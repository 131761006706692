import { ref } from 'vue'
import { plainToClass } from '/-/plugins/helpers'

export interface NotificationInterface {
  title: string
  description?: string
  type?: string
  icon?: string
  theme?: string
  duration?: number
}

export class Notification implements NotificationInterface {
  title!: string
  description!: string
  icon = 'outline_check_circle'
  theme = 'success'
}

const DURATION = 5000
const state = ref([] as Notification[])

function pushNotification(params: NotificationInterface) {
  state.value.push(plainToClass(params, Notification))

  setTimeout(() => {
    if (state.value.length > 0) {
      state.value.splice(0, 1)
    }
  }, params.duration || DURATION)
}

function removeNotification(notification: Notification) {
  state.value = state.value.filter(n => n !== notification)
}

export function useNotifications() {
  return { list: state, pushNotification, removeNotification }
}
