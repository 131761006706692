import * as Sentry from '@sentry/vue'
import router from '/~/router'

function processError(e: unknown, isThrow = false) {
  console.error(e) // sentry doesn't log errors on explicit capture
  if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_ENV === 'production') {
    Sentry.captureException(e)
  }

  if (isThrow) {
    throw (e)
  }
}

function showNotFoundPage() {
  router.replace({ name: '404', params: { catchAll: router.currentRoute.value.path.substring(1).split('/') }})
}

export function useError() {
  return {
    processError,
    showNotFoundPage
  }
}
