import isEqual from 'lodash.isequal'

export default function difference(object: any, base: any) {
  if (isEqual(object, base)) {
    return {}
  }

  const diff: any = {}

  for (const key in object) {
    if (!isEqual(object[key], base[key])) {
      diff[key] = object[key]
    }
  }

  return diff
}
