import { Expose, Transform, Type } from 'class-transformer'
import { Member } from '/~/models/member'
import { Company, CompanyIdType } from '/~/models/company'
import { CityInterface, CountryInterface } from '/~/models/geo'
import { enumToOptions } from '/~/plugins/utils'
import { OptionInterface } from '/-/components/controls/setup'
import { Media } from './media'

export type UserIdType = number

export enum UserStatus {
  Active = 10,
  Deleted = 0,
}

export const USER_STATUS_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(UserStatus)

export const enum UserMediaCategory {
  Logo = 'logo',
  Image = 'image',
  Background = 'background',
}

export enum UserCompanyRole {
  Chat = 'chat',
  Admin = 'admin',
}
export enum UserRole {
  Admin = 'admin',
  SuperAdmin = 'super_admin',
  OrdersAdmin = 'orders_admin',
  SalesManager = 'sales_manager',
}

export const USER_ROLE_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(UserRole)

export class UserBase {
  id!: UserIdType
  job!: string
  email!: string

  @Expose({ name: 'first_name' })
  firstName!: string

  @Expose({ name: 'last_name' })
  lastName!: string

  roles: UserRole[] = []

  media?: Media[]

  get avatar(): Media | undefined {
    return this.media?.filter(({ category }) => category === UserMediaCategory.Logo)?.[0]
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get isAdmin(): boolean {
    return this.roles.includes(UserRole.Admin)
  }

  get isSuperAdmin(): boolean {
    return this.roles.includes(UserRole.SuperAdmin)
  }

  get isOrdersAdmin(): boolean {
    return this.roles.includes(UserRole.OrdersAdmin)
  }

  get isSalesManager(): boolean {
    return this.roles.includes(UserRole.SalesManager)
  }

  get isSalesManagerNotSuperAdmin(): boolean {
    return this.roles.includes(UserRole.SalesManager) && !this.isSuperAdmin
  }
}

export class UserLocalization extends UserBase {
  locale!: string
  bio!: string

  @Expose({ name: 'user_id' })
  userId!: number
}

export class User extends UserBase {
  @Type(() => Company)
  @Expose({ toClassOnly: true })
  company?: Company

  @Transform(({ obj }) => { return obj.company?.id }, { toClassOnly: true })
  @Expose({ name: 'company_id' })
  companyId?: CompanyIdType

  status: UserStatus = 10
  bio?: string
  linkedin?: string

  city!: CityInterface

  country!: CountryInterface

  @Transform(({ obj }) => { return obj.city?.id || null }, { toClassOnly: true })
  @Expose({ name: 'city_id' })
  cityId!: number | undefined

  @Transform(({ obj }) => { return obj.country?.id || null }, { toClassOnly: true })
  @Expose({ name: 'country_id' })
  countryId!: string | undefined

  @Transform(({ obj }) => { return obj.timezone?.id || null }, { toClassOnly: true })
  timezone?: string

  @Expose({ name: 'disable_email_notifications' })
  disableEmailNotifications!: boolean

  @Expose({ name: 'is_password_set' })
  passwordSet!: boolean

  @Expose({ name: 'last_seen', toClassOnly: true })
  lastSeen!: string

  @Expose({ name: 'created_at', toClassOnly: true })
  createdAt!: string

  @Expose({ name: 'updated_at', toClassOnly: true })
  updatedAt!: string

  @Expose({ name: 'event_participation' })
  @Type(() => Member)
  events!: Member[]

  @Expose({ name: 'user_localization' })
  @Type(() => UserLocalization)
  userLocalization: UserLocalization[] = []

  @Expose({ name: 'password', toPlainOnly: true })
  newPassword?: string

  languages!: string | null
}

// user field for questions, comments etc
export class SimpleUser {
  id!: number
  name!: string
  media?: string
}
