export enum RouteNames {
  Tickets = 'event-tickets',
  TicketCreate = 'event-ticket-create',
  Ticket = 'event-ticket',
  TicketEdit = 'event-ticket-edit',
  TicketTemplates = 'ticket-templates',
  TicketTemplateCreate = 'ticket-template-create',
  TicketTemplate = 'ticket-template',
  TicketTemplateEdit = 'ticket-template-edit',
  CertificateTemplates = 'certificate-templates',
  CertificateTemplateCreate = 'certificate-template-create',
  CertificateTemplate = 'certificate-template',
  CertificateTemplateEdit = 'certificate-template-edit',
  MailingsList = 'mailings',
  MailingsCreate = 'mailings-create',
  MailingsView = 'mailings-view',
  PromoCodes = 'promo-codes',
  PromoCodeCreate = 'promo-code-create',
  PromoCode = 'promo-code',
  PromoCodeEdit = 'promo-code-edit',
  TildaPages = 'tilda-pages',
  TildaPageCreate = 'tilda-page-create',
  TildaPage = 'tilda-page',
  TildaPageEdit = 'tilda-page-edit',
  Orders = 'orders',
  Order = 'order',
  OrderLog = 'order-log',
  OrderCreate = 'order-create',
  OrderEdit = 'order-edit',
  Badges = 'event-badges',
  BadgesTemplates = 'event-badges-templates',
  BadgesTemplateCreate = 'event-badges-template-create',
  BadgesTemplate = 'event-badges-template',
  BadgesPrint = 'event-badges-print',
  MeetingSettings = 'meeting-settings',
  MeetingLocations = 'meeting-locations',
  WNMeetVideos = 'wnmeet-videos',
  WNMeetVideo = 'wnmeet-video',
}
