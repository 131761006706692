export default {
  twitch: 'Twitch',
  twitch_video: 'Twitch Video',
  youtube: 'YouTube',
  iframe: 'IFrame',
  Open: 'Open',
  OpenIfAgree: 'Open if agree',
  OpenIfTicket: 'Open if have ticket',
  Closed: 'Closed'
}
