import { Transform, Expose, Type } from 'class-transformer'
import { OptionInterface } from '/-/components/controls/setup'
import { Media } from '/~/models/media'
import { enumToOptions, optionsToMap } from '/~/plugins/utils'
import { useCatalogs } from '/~/state/catalogs'
import { UserBase } from '/~/models/user'

export const CompanySize = new Map<number, string>([
  [1, '1-10'],
  [2, '11-30'],
  [3, '31-70'],
  [4, '71-100'],
  [5, '101-150'],
  [6, '151-300'],
  [7, 'over 300'],
])

export enum CompanyCategoryType {
  Media = 1
}

export class CompanyCategory {
  id!: CompanyCategoryType

  @Expose({ name: 'event_id' })
  eventId!: number
}

export type CompanyIdType = number

export enum CompanyStatus {
  New = 1,
  Approved = 2,
  Hidden = 3
}

interface CompanyBusiness {
  id: number
  name: string
}

interface CompanyTag {
  id: number
  name: string
}

export const COMPANY_STATUS_OPTIONS: Readonly<OptionInterface[]> = enumToOptions(CompanyStatus)
export const CompanyStatusMap = optionsToMap(COMPANY_STATUS_OPTIONS)

export enum CompanySponsorType {
  Bronze = 1,
  Silver,
  Gold,
  Platinum,
  Organizer,
  General,
  Diamond,
  Sponsor,
  Partner,
  Media
}

export const SPONSOR_TYPE_OPTIONS: OptionInterface[] = [
  {
    value: 0,
    label: '—'
  },
  {
    value: CompanySponsorType.Bronze,
    label: 'Bronze Sponsor'
  },
  {
    value: CompanySponsorType.Silver,
    label: 'Silver Sponsor'
  },
  {
    value: CompanySponsorType.Gold,
    label: 'Gold Sponsor'
  },
  {
    value: CompanySponsorType.Platinum,
    label: 'Platinum Sponsor'
  },
  {
    value: CompanySponsorType.Organizer,
    label: 'Organizer'
  },
  {
    value: CompanySponsorType.General,
    label: 'General Sponsor'
  },
  {
    value: CompanySponsorType.Diamond,
    label: 'Diamond Sponsor'
  },
  {
    value: CompanySponsorType.Sponsor,
    label: 'Sponsor'
  },
  {
    value: CompanySponsorType.Partner,
    label: 'Partner'
  },
  {
    value: CompanySponsorType.Media,
    label: 'Media'
  },
]

export class CompanyEventData {
  @Expose({ name: 'event_id' })
  eventId!: number

  // fixToTop must be boolean, but there are some old data with null instead
  @Transform(({ value }) => !!value)
  @Expose({ name: 'fix_to_top' })
  fixToTop!: boolean

  @Expose({ name: 'sponsor_type' })
  @Transform(({ value }) => { return value === null ? 0 : value })
  sponsorType!: number | null
}

export class Company {
  id!: CompanyIdType
  name!: string
  description!: string
  logo!: Media | null
  size!: number
  site!: string
  email!: string

  @Expose({ name: 'wn_manager' })
  @Type(() => UserBase)
  wnManager!: UserBase

  @Expose({ name: 'wn_manager_id' })
  wnManagerId!: number

  @Expose({ name: 'market_email' })
  marketplaceEmail!: string

  media!: Media[]
  status!: CompanyStatus
  geo!: string

  @Type(() => CompanyCategory)
  categories!: CompanyCategory[]

  @Transform(({ value }) => { return value?.map((item: CompanyBusiness) => item.id) }, { toClassOnly: true })
  business!: CompanyBusiness[]

  @Transform(({ value }) => { return value?.map((item: CompanyTag) => item.id) }, { toClassOnly: true })
  tags!: CompanyTag[]

  get primaryBusiness() {
    const { business } = useCatalogs()

    return this.business.map(item => {
      return business.value?.find(({ value }) => String(value) === String(item))
    }).filter(item => item)
  }

  get tagsItems() {
    const { tags } = useCatalogs()

    return this.tags.map(item => {
      return tags.value.find(({ value }) => String(value) === String(item))
    }).filter(item => item)
  }

  @Type(() => CompanyEventData)
  events!: CompanyEventData[]

  get sizeCaption(): string {
    return CompanySize.get(this.size) || ''
  }
}
