// MemberRole is here to avoid the error of recursive exports from models/event.ts to models/member.ts and back

export enum MemberRole {
  Member = 1,
  Moderator,
  Helper,
  CourseAdmin,
  CourseTeacher,
  CourseStudent,
  Sponsor,
  Speaker,
  Developer,
}
