import { Expose, Transform } from 'class-transformer'
import { EventIdType } from './event'

export type TicketIdType = number

export enum FeatureValueType {
  Number,
  Boolean
}

export enum TicketFeatureName {
  LimitedChats = 'limited_chats',
  LimitedMeetings = 'limited_meetings',
  LimitedVideoCall = 'limited_video_call',
  UnlimitedChats = 'unlimited_chats',
  UnlimitedMeetings = 'unlimited_meetings',
  UnlimitedVideoCall = 'unlimited_video_call',
  Highlight = 'highlight',
  GamePoll= 'game_poll',
  WatchLecture = 'watch_lecture',
  Jury = 'jury',
  Offline = 'offline',
  Pro = 'pro_account'
}

interface TicketFeatureInterface {
  valueType: FeatureValueType
}

export const TicketFeaturesSpec: Record<TicketFeatureName, TicketFeatureInterface> = {
  [TicketFeatureName.LimitedChats]: { valueType: FeatureValueType.Number },
  [TicketFeatureName.LimitedMeetings]: { valueType: FeatureValueType.Number },
  [TicketFeatureName.LimitedVideoCall]: { valueType: FeatureValueType.Number },
  [TicketFeatureName.UnlimitedChats]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.UnlimitedMeetings]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.UnlimitedVideoCall]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.Highlight]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.GamePoll]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.WatchLecture]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.Jury]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.Offline]: { valueType: FeatureValueType.Boolean },
  [TicketFeatureName.Pro]: { valueType: FeatureValueType.Boolean },
}

const DefaultFeatureValues: Record<TicketFeatureName, number | boolean> = {
  [TicketFeatureName.LimitedChats]: 0,
  [TicketFeatureName.LimitedMeetings]: 0,
  [TicketFeatureName.LimitedVideoCall]: 0,
  [TicketFeatureName.UnlimitedChats]: true,
  [TicketFeatureName.UnlimitedMeetings]: true,
  [TicketFeatureName.UnlimitedVideoCall]: true,
  [TicketFeatureName.Highlight]: false,
  [TicketFeatureName.GamePoll]: false,
  [TicketFeatureName.WatchLecture]: false,
  [TicketFeatureName.Jury]: false,
  [TicketFeatureName.Offline]: false,
  [TicketFeatureName.Pro]: false,
}

export type TicketFeatures = {
  [key in TicketFeatureName]: number | boolean
}

export const HiddenFeatures: TicketFeatureName[] = [
  TicketFeatureName.LimitedChats,
  TicketFeatureName.LimitedMeetings,
  TicketFeatureName.LimitedVideoCall,
  TicketFeatureName.UnlimitedChats,
  TicketFeatureName.UnlimitedMeetings,
  TicketFeatureName.UnlimitedVideoCall,
]

export class Ticket {
  static defaultFeatures = Object.keys(TicketFeaturesSpec).reduce((map, feature) => {
    return {
      ...map,
      [feature]: DefaultFeatureValues[feature as TicketFeatureName]
    }
  }, {}) as TicketFeatures

  static featureNames = Object.keys(Ticket.defaultFeatures) as TicketFeatureName[]

  static isTicketFeatureBoolean(feature: TicketFeatureName): boolean {
    return TicketFeaturesSpec[feature].valueType === FeatureValueType.Boolean
  }

  id!: TicketIdType

  @Expose({ name: 'event_id' })
  eventId!: EventIdType

  name!: string
  subtitle!: string

  paid = false

  upgradable = false

  count?: number // for client form

  @Expose({ name: 'template_id' })
  templateId!: number | null

  @Expose({ name: 'email_template_id' })
  emailTemplateId!: number | null

  usd!: number | null
  eur!: number | null
  rub!: number | null

  @Expose({ name: 'future_price' })
  futurePrice!: number | null

  description!: string | null

  // api respond with an array of active features, boolean features counted as enabled even with null value
  @Transform(({ value }) => {
    return value
      .reduce((map: TicketFeatures, { feature, value }: { feature: TicketFeatureName, value: string }) => {
        return {
          ...map,
          [feature]: Ticket.isTicketFeatureBoolean(feature) ? true : value
        }
      }, { ...Ticket.defaultFeatures })
  }, { toClassOnly: true })
  @Transform(({ value }) => {
    return Object.keys(value).map(feature => {
      return {
        feature,
        value: Ticket.isTicketFeatureBoolean(feature as TicketFeatureName) && value[feature] ? null : value[feature],
      }
    }).filter(feature =>
      Ticket.isTicketFeatureBoolean(feature.feature as TicketFeatureName) ? feature.value === null : !!feature.value)
  }, { toPlainOnly: true })
  features: TicketFeatures = { ...Ticket.defaultFeatures }
}

export type TicketsRecord = Record<number, Ticket>
