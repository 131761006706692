export default {
  drag_anchor: '<svg width=\'256px\' height=\'256px\' viewBox=\'0 0 256 256\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M76,92.0001a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,76,92.0001Zm52-16a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,128,76.0001Zm68,32a16,16,0,1,0-16-16A16.00016,16.00016,0,0,0,196,108.0001Zm-136,40a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,60,148.0001Zm68,0a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,128,148.0001Zm68,0a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,196,148.0001Z\'/></svg>',
  menu_archive: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'19.471\' height=\'18.117\' viewBox=\'0 0 19.471 18.117\'><g id=\'archive\' transform=\'translate(-0.25 -0.366)\'><path id=\'Контур_551\' data-name=\'Контур 551\' d=\'M17.337,7.093V17.733H2.634V7.093M18.971,3H1V7.093H18.971Z\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_552\' data-name=\'Контур 552\' d=\'M10,12h3.274\' transform=\'translate(-1.652 -1.633)\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_561\' data-name=\'Контур 561\' d=\'M0,0H17.963V18H0Z\' transform=\'translate(0.971 0.366)\' fill=\'none\'/></g></svg>',
  menu_article: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'19.5\' height=\'19.5\' viewBox=\'0 0 19.5 19.5\'><g id=\'article\' transform=\'translate(-2.25 -2.25)\'><path id=\'Контур_570\' data-name=\'Контур 570\' d=\'M21,3H3V21H21Z\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_571\' data-name=\'Контур 571\' d=\'M12,6H6v4h6Zm3,0h3M15,9h3M6,14H18M6,17H18\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/></g></svg>',
  menu_calendar: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'18\' height=\'19.677\' viewBox=\'0 0 18 19.677\'><g id=\'calendar\' transform=\'translate(-2.971 -2.25)\'><path id=\'Контур_560\' data-name=\'Контур 560\' d=\'M0,0H18V18H0Z\' transform=\'translate(2.971 3)\' fill=\'none\'/><path id=\'Контур_555\' data-name=\'Контур 555\' d=\'M20,5H4V21H20ZM4,12H20M8,3V7m8-4V7\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_556\' data-name=\'Контур 556\' d=\'M10,21a7.272,7.272,0,0,0,5-5s4,0,5-3\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/></g></svg>',
  menu_chat_message_alt: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'19.706\' height=\'19.706\' viewBox=\'0 0 19.706 19.706\'><path id=\'chat-message-alt\' d=\'M3,12a9,9,0,1,1,9,9,8.446,8.446,0,0,1-4-1L3,21l1-5a9.008,9.008,0,0,1-1-4Zm5.01-2h8m-8,4h6\' transform=\'translate(-2.044 -2.25)\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'1.5\'/></svg>',
  menu_clipboard: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'15.5\' height=\'19.5\' viewBox=\'0 0 15.5 19.5\'><g id=\'clipboard\' transform=\'translate(-4.25 -2.25)\'><path id=\'Контур_549\' data-name=\'Контур 549\' d=\'M15.625,4H19V21H5V4H8.375\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_550\' data-name=\'Контур 550\' d=\'M15,3H9V6h6Z\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/></g></svg>',
  menu_cube: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'18\' height=\'19.5\' viewBox=\'0 0 18 19.5\'><g id=\'cube\' transform=\'translate(-3.03 -2.25)\'><path id=\'Контур_559\' data-name=\'Контур 559\' d=\'M0,0H18V18H0Z\' transform=\'translate(3.03 3)\' fill=\'none\'/><path id=\'Контур_557\' data-name=\'Контур 557\' d=\'M4.059,16.765,12.029,21,20,16.765V7.235L12.029,3,4.059,7.235v9.53Z\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_558\' data-name=\'Контур 558\' d=\'M4.059,7.235l7.97,4.236L20,7.235M12.029,21V11.471\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/></g></svg>',
  menu_map_pin_location_alt: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'19.5\' height=\'19.5\' viewBox=\'0 0 19.5 19.5\'><g id=\'map-pin-location-alt\' transform=\'translate(-2.25 -2.25)\'><path id=\'Контур_544\' data-name=\'Контур 544\' d=\'M17.625,16C19.683,16.515,21,17.306,21,18.192,21,19.743,16.97,21,12,21s-9-1.257-9-2.808C3,17.306,4.317,16.514,6.375,16\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_545\' data-name=\'Контур 545\' d=\'M12,11v7m0-7A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_546\' data-name=\'Контур 546\' d=\'M10,6A1,1,0,1,0,9,5,1,1,0,0,0,10,6Z\' transform=\'translate(2 2)\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-width=\'1\'/></g></svg>',
  menu_play: '<svg viewBox=\'0 0 18 21\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path id=\'Polygon 1\' d=\'M16.2783 8.80289C17.534 9.58588 17.534 11.4141 16.2783 12.1971L3.30822 20.2844C1.97605 21.1151 0.250001 20.1572 0.250001 18.5873L0.250002 2.41268C0.250002 0.842755 1.97605 -0.115089 3.30821 0.71557L16.2783 8.80289Z\' fill=\'currentColor\'/></svg>',
  menu_users: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'19.528\' height=\'19.51\' viewBox=\'0 0 19.528 19.51\'><g id=\'users\' transform=\'translate(-2.25 -2.242)\'><path id=\'Контур_553\' data-name=\'Контур 553\' d=\'M10.412,15.706a4.235,4.235,0,1,0-4.235-4.235,4.235,4.235,0,0,0,4.235,4.235ZM3,21a7.384,7.384,0,0,1,4.256-6.7m6.311,0A7.385,7.385,0,0,1,17.824,21M16.31,10.26A7.325,7.325,0,0,1,21,16.82\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/><path id=\'Контур_554\' data-name=\'Контур 554\' d=\'M14.61,10.95A4,4,0,1,0,10,7a2.264,2.264,0,0,0,.01.26\' fill=\'none\' stroke=\'currentColor\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\'/></g></svg>',
  menu_work: '<svg id=\'work_outline_black_24dp\' xmlns=\'http://www.w3.org/2000/svg\' width=\'18\' height=\'18\' viewBox=\'0 0 18 18\'><path id=\'Контур_28\' data-name=\'Контур 28\' d=\'M0,0H18V18H0Z\' fill=\'none\'/><path id=\'Контур_29\' data-name=\'Контур 29\' d=\'M12.8,5.519V3.759H9.2V5.519Zm-9,1.759v9.677H18.2V7.278ZM18.2,5.519A1.774,1.774,0,0,1,20,7.278v9.677a1.774,1.774,0,0,1-1.8,1.759H3.8A1.774,1.774,0,0,1,2,16.955l.009-9.677A1.766,1.766,0,0,1,3.8,5.519H7.4V3.759A1.774,1.774,0,0,1,9.2,2h3.6a1.774,1.774,0,0,1,1.8,1.759V5.519Z\' transform=\'translate(-2 -2)\' fill=\'currentColor\'/></svg>',
  text_align_center: '<?xml version=\'1.0\' encoding=\'UTF-8\'?><svg width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'><g id=\'text-edit\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'><g id=\'text-align-center\' fill=\'#000000\'><path d=\'M19,7 L5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 L19,5 C19.5522847,5 20,5.44771525 20,6 C20,6.55228475 19.5522847,7 19,7 Z M17,11 L7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.44771525 6.44771525,9 7,9 L17,9 C17.5522847,9 18,9.44771525 18,10 C18,10.5522847 17.5522847,11 17,11 Z M19,15 L5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 L19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 Z M17,19 L7,19 C6.44771525,19 6,18.5522847 6,18 C6,17.4477153 6.44771525,17 7,17 L17,17 C17.5522847,17 18,17.4477153 18,18 C18,18.5522847 17.5522847,19 17,19 Z\' id=\'Shape\'></path></g></g></svg>',
  text_align_left: '<?xml version=\'1.0\' encoding=\'UTF-8\'?><svg width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'><g id=\'text-edit\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'><g id=\'text-align-left\' fill=\'#000000\'><path d=\'M19,7 L5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 L19,5 C19.5522847,5 20,5.44771525 20,6 C20,6.55228475 19.5522847,7 19,7 Z M15,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 L15,9 C15.5522847,9 16,9.44771525 16,10 C16,10.5522847 15.5522847,11 15,11 Z M19,15 L5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 L19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 Z M15,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 L15,17 C15.5522847,17 16,17.4477153 16,18 C16,18.5522847 15.5522847,19 15,19 Z\' id=\'Shape\'></path></g></g></svg>',
  text_align_right: '<?xml version=\'1.0\' encoding=\'UTF-8\'?><svg width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'><g id=\'text-edit\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'><g id=\'text-align-right\' fill=\'#000000\'><path d=\'M19,7 L5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 L19,5 C19.5522847,5 20,5.44771525 20,6 C20,6.55228475 19.5522847,7 19,7 Z M19,11 L9,11 C8.44771525,11 8,10.5522847 8,10 C8,9.44771525 8.44771525,9 9,9 L19,9 C19.5522847,9 20,9.44771525 20,10 C20,10.5522847 19.5522847,11 19,11 Z M19,15 L5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 L19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 Z M19,19 L9,19 C8.44771525,19 8,18.5522847 8,18 C8,17.4477153 8.44771525,17 9,17 L19,17 C19.5522847,17 20,17.4477153 20,18 C20,18.5522847 19.5522847,19 19,19 Z\' id=\'Shape\'></path></g></g></svg>',
  text_bold: '<?xml version=\'1.0\' encoding=\'UTF-8\'?><svg width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'><g id=\'text-edit\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'><g id=\'bold\' fill=\'#000000\'><path d=\'M5,20 C5,20 5,4 5,4 C5,2.9000001 5.9000001,2 7,2 C7,2 8.66666667,2 12,2 C15,2 17.9655172,3.4000001 18,6.91891892 C18,8.75675676 17.0689655,10.6486486 15.4682396,11.2432432 L15.4682396,11.3783784 C17.5009074,11.8918919 19,13.4054054 19,16.1081081 C19,17.1299184 18.7971525,18.0125222 18.4267395,18.7607762 C17.3373286,20.9614441 15.0906889,22 12,22 C8.66666667,22 7,22 7,22 C5.9000001,22 5,21.1000004 5,20 Z M8,19 L12,19 C14.5662432,19 16,17.8000002 16,16 C16,14.1999998 14.5916515,13 12,13 L8,13 L8,19 Z M8,10 L11,10 C13.3883848,10 15,9.14999962 15,7.44999981 C15,5.75 13.5,5 10.9963702,5 L8,5 L8,10 Z\' id=\'Shape\'></path></g></g></svg>',
  text_italic: '<?xml version=\'1.0\' encoding=\'UTF-8\'?><svg width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'><g id=\'text-edit\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'><g id=\'italic\' fill=\'#000000\'><path d=\'M8.96981178,19 L12.7211005,5 L10,5 C9.44771525,5 9,4.55228475 9,4 C9,3.44771525 9.44771525,3 10,3 L18,3 C18.5522847,3 19,3.44771525 19,4 C19,4.55228475 18.5522847,5 18,5 L14.7916528,5 L11.0403641,19 L14,19 C14.5522847,19 15,19.4477153 15,20 C15,20.5522847 14.5522847,21 14,21 L6,21 C5.44771525,21 5,20.5522847 5,20 C5,19.4477153 5.44771525,19 6,19 L8.96981178,19 Z\' id=\'Shape\'></path></g></g></svg>',
  text_resize: '<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 1000 1000\' xml:space=\'preserve\'><path d=\'M783.85 143.3 651.13 276.25V95.6l-50.06.47-50.3.71v351.86h351.86l.71-50.3.47-50.06H723.16l133.42-133.42L990 81.43l-35.42-35.42c-19.36-19.36-35.89-35.42-36.6-35.42S857.05 70.34 783.85 143.3zM97.85 550.65c-.95.71-1.65 23.62-1.65 50.77v49.12h180.64L143.42 783.96 10 917.39l36.13 35.89 35.89 36.13 133.42-133.42 133.42-133.42V903.22l50.3-.47 50.06-.71V550.18l-174.98-.71c-96.1-.23-175.69.24-176.39 1.18z\' fill=\'#1f2937\'/></svg>',
  text_rotation: '<svg width=\'48px\' height=\'48px\' viewBox=\'0 0 48 48\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><rect width=\'48\' height=\'48\' fill=\'none\'/><path d=\'M13 29L16.6667 21M35 29L31.3333 21M31.3333 21L29.5 17L24 5L18.5 17L16.6667 21M31.3333 21H16.6667\'stroke=\'black\' stroke-width=\'4\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M6 37H42L36 43\' stroke=\'black\' stroke-width=\'4\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>',
  outline_arrow_turn_left: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width=\'2\' d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" /></svg>',
  outline_arrow_path: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>'
}
