export default {
  Registration: 'Registration',
  Lecture: 'Lecture Hall',
  CoffeeBreak: 'Coffee Break',
  RoundTable: 'Roundtable Discussion',
  Deconstruction: 'Deconstruction',
  Party: 'Party',
  AwardsCeremony: 'Awards Ceremony',
  None: 'None',
  SoloTalk: 'Solo Talk',
  DuoTalk: 'Duo Talk',
  PanelDiscussion: 'Panel Discussion',
  FiresideChat: 'Fireside Chat',
  HappyHour: 'Happy Hour',
  KeynoteSpeaker: 'Keynote speaker',
  QAndA: 'Q&A',
  Lunch: 'Lunch',
  TrioTalk: 'Trio Talk',
  Masterclass: 'Masterclass',
  InteractiveSession: 'Interactive Session'
}
