import { Expose, Transform } from 'class-transformer'
import { LocaleAbbr } from './locale'

export class Media {
  id!: number
  category!: 'logo' | 'image' | 'background'
  name!: string

  @Expose({ name: 'mime_type' })
  mimeType!: string

  @Expose({ name: 'thumb_url' })
  thumbUrl!: string

  @Transform(({ value }) => value || LocaleAbbr.EN, { toClassOnly: true })
  locale!: LocaleAbbr

  lr!: string
  hr!: string
  url!: string

  file?: File // for temp storage

  get isImage() {
    return this.mimeType.split('/')[0].includes('image')
  }

  get fullName() {
    return this.name + '.' + this.url.split('.').pop()
  }
}

export const getLocalMedia = (media: Media[], locale?: LocaleAbbr) => {
  return media.find(item => item.locale === locale)
}
