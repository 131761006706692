export default {
  title: 'Sign in to Dashboard',
  forgot: 'Forgot Password?',
  sign_in: 'Sign in',
  remember: 'Remember me',
  create: 'Create an account',
  create_question: 'Don\'t have an account?',
  back_to_login: 'Go back to login',
  reset: 'Reset',
  recovery_title: 'Recovery password',
  recovery_info: 'Enter your user account\'s verified email address and we will send you a password reset link.',
  reset_title: 'Create your password',
  reset_info: 'Enter your new password.',
  reset_success_title: 'Check your inbox!',
  reset_success_text: 'Email with instructions for resetting your password has been sent to',
  passwords_not_match: 'The specified passwords must be identical',
  login_by_google: 'Sign in with Google',
  link_send_title: 'Magic Done',
  link_send_text: 'We sent an email to you at :email. It has a magic link that\'ll sign you in.',
  type_password: 'Type Password',
  send_magic_link: 'Send Magic Link',
}
