import { Expose, Transform, Type } from 'class-transformer'
import { Media } from '/~/models/media'
import { UserBase } from '/~/models/user'
import type { ConfigInterface } from '/-/models/config'
import { enumToOptions, optionsToMap, parseJsonClassField, stringifyJsonClassField } from '/~/plugins/utils'
import { Currency } from '/~/models/currency'
import { MemberRole } from '/~/models/common'
import type { OptionInterface } from '/-/components/controls/setup'
export type { ConfigInterface, EventLocationInterface } from '/-/models/config'

export type EventIdType = number

export enum EventStatus {
  Draft = 'draft',
  Disabled = 'disabled',
  Open = 'open'
}

export enum EventType {
  Default,
  Course,
}

export const EventStatusOptions = Object.keys(EventStatus).map(key => (
  {
    label: key,
    value: EventStatus[key as keyof typeof EventStatus],
  }
))

export const EventTypeOptions = enumToOptions(EventType, true)

export enum EventSubtype {
  Conference,
  Connect,
  'C-Level Summit',
  Other,
  Summit,
}

export const EventSubtypeOptionsSorted: OptionInterface[] = [
  { value: EventSubtype.Conference, label: 'Conference' },
  { value: EventSubtype.Summit, label: 'Summit' },
  { value: EventSubtype['C-Level Summit'], label: 'C-Level Summit' },
  { value: EventSubtype.Connect, label: 'Connect' },
  { value: EventSubtype.Other, label: 'Other' },
]

export enum EventRegion {
  Europe,
  APAC,
  LATAM,
  MENA,
  NORAM,
  AFRICA,
  MEAST,
  CIS
}

export enum CourseLevel {
  Junior,
  'Junior+',
  Middle,
  'Middle+',
  Senior
}

export enum CourseSubject {
  GameDesign,
  Management,
  Programming,
  Other,
}

export const CourseSubjectOptions = enumToOptions(CourseSubject, true, true)

export const CourseLevelOptions = enumToOptions(CourseLevel, true, true)

export const EventRegionOptions = enumToOptions(EventRegion, true)

export enum EventTopStatus {
  'No' = 1,
  'For users with ticket',
  'For everyone'
}

export const EventTopStatusOptions = enumToOptions(EventTopStatus, true)
export const EventTopStatusMap = optionsToMap(EventTopStatusOptions)

export enum EventRequiredFieldType {
  Job = 'job',
  Company = 'company',
  Country = 'country',
  City = 'city',
  Avatar = 'avatar',
}

export class EventRequiredField {
  type!: EventRequiredFieldType
  name!: string
  required!: boolean
}

export const EventRequiredFieldsDefault = Object.entries(EventRequiredFieldType).map(entry => ({
  name: entry[0],
  type: entry[1],
  required: true
}))

export class Event {
  id!: EventIdType
  slug!: string
  name!: string
  icon!: string
  color!: string
  type!: EventType
  city!: string | null

  @Transform(parseJsonClassField, { toClassOnly: true })
  @Transform(stringifyJsonClassField, { toPlainOnly: true })
  tagline!: string | Record<string, string>

  @Expose({ name: 'country_id' })
  countryId!: string | null

  @Expose({ name: 'admin_emails' })
  adminEmails: string[] = []

  @Expose({ name: 'fin_manager_ids' })
  finManagerIds: number[] = []

  @Type(() => UserBase)
  @Expose({ name: 'fin_managers' })
  finManagers: UserBase[] = []

  @Expose({ name: 'sort_order' })
  sortOrder!: number

  @Expose({ name: 'default_member_role' })
  defaultMemberRole: MemberRole = 1

  @Expose({ name: 'linked_events_users' })
  linkedEventsUsers!: number[]

  @Expose({ name: 'linked_events_lectures' })
  linkedEventsLectures!: number[]

  @Expose({ name: 'date_from' })
  dateFrom!: string

  @Expose({ name: 'date_to' })
  dateTo!: string

  @Expose({ name: 'hub_date_from' })
  hubDateFrom!: string | null

  @Expose({ name: 'hub_date_to' })
  hubDateTo!: string | null

  @Expose({ name: 'custom_date' })
  @Transform(parseJsonClassField, { toClassOnly: true })
  @Transform(stringifyJsonClassField, { toPlainOnly: true })
  customDate!: string | Record<string, string>

  timezone!: string
  latitude?: number
  longitude?: number
  status!: EventStatus
  config!: ConfigInterface
  host!: string

  @Type(() => Media)
  media: Media[] = []

  @Type(() => Media)
  logo: Media[] = []

  @Type(() => Media)
  background: Media[] = []

  @Expose({ name: 'is_show' })
  isShowInEventsList = false

  @Expose({ name: 'is_show_for_member' })
  isShowForMember = false

  @Expose({ name: 'is_online' })
  isOnline = false

  @Expose({ name: 'is_onsite' })
  isOnsite = false

  @Expose({ name: 'auto_reg' })
  autoRegTicketId!: number

  address!: string

  @Expose({ name: 'address_link' })
  addressLink!: string

  @Expose({ name: 'full_address' })
  fullAddress!: string

  description!: string

  @Expose({ name: 'preview_description' })
  metaDescription!: string

  @Expose({ name: 'event_type' })
  subtype!: EventSubtype | null

  @Expose({ name: 'main_currency' })
  currency!: null | Currency

  @Expose({ name: 'mail_from_email' })
  mailFromEmail!: null | string

  @Expose({ name: 'mail_from_name' })
  mailFromName!: null | string

  @Expose({ name: 'request_email' })
  requestEmail!: null | string

  @Expose({ name: 'top_status' })
  topStatus!: EventTopStatus

  regions: EventRegion[] = []

  @Expose({ name: 'course_level' })
  courseLevel!: CourseLevel | null

  @Expose({ name: 'course_subject' })
  courseSubject!: CourseSubject | null

  @Expose({ name: 'custom_language' })
  @Transform(parseJsonClassField, { toClassOnly: true })
  @Transform(stringifyJsonClassField, { toPlainOnly: true })
  customLanguage!: string | Record<string, string>

  @Expose({ name: 'custom_price' })
  @Transform(parseJsonClassField, { toClassOnly: true })
  @Transform(stringifyJsonClassField, { toPlainOnly: true })
  customPrice!: string | Record<string, string>

  @Expose({ name: 'required_fields' })
  requiredFields: EventRequiredField[] = []

  get isCourse() {
    return this.type === EventType.Course
  }

  get currencyDefault() {
    return Currency.EUR
  }

  get mailFromNameDefault() {
    return this.type === EventType.Course ? 'WN Academy' : this.name
  }

  get mailFromEmailDefault() {
    return this.type === EventType.Course ? 'academy@wn.media' : 'noreply@wn.media'
  }

  get requestEmailDefault() {
    return 'welcome@wn.media'
  }

  get generalHubLink(): string {
    const path = this.type === EventType.Course ? 'academy' : 'event-info'

    return `https://wnhub.io/${path}/${this.slug}`
  }

  get allowedMemberRoles() {
    switch (this.type) {
    case EventType.Course:
      return [
        MemberRole.Helper,
        MemberRole.Member,
        MemberRole.Moderator,
        MemberRole.CourseStudent,
        MemberRole.CourseTeacher,
        MemberRole.CourseAdmin,
      ]
    default:
      return [
        MemberRole.Helper,
        MemberRole.Member,
        MemberRole.Moderator,
        MemberRole.Sponsor,
        MemberRole.Speaker,
        MemberRole.Developer,
      ]
    }
  }

  get allowedDefaultMemberRoles() {
    switch (this.type) {
    case EventType.Course:
      return [MemberRole.Member, MemberRole.CourseStudent]
    default:
      return [MemberRole.Member]
    }
  }
}

export enum EventMediaCategory {
  Logo = 'logo',
  Background = 'background'
}

export const EventMediaCategoryDescription = {
  [EventMediaCategory.Logo]: 'Preview image for events list',
  [EventMediaCategory.Background]: 'Background'
}
