import { createRouter, createWebHistory } from 'vue-router'
import Storage from '/-/plugins/local-storage'
import routes from './routes'
import { cNotM } from 'naive-ui'

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

const ROUTER_STORAGE_KEY = 'router'
const RELOAD_COUNT_LIMIT = 1
const RELOAD_TIME_LIMIT = 5000

interface RouterStorageDataInterface {
  reloadCount: number
  reloadTime: number
}
const defaultReloadData = { reloadCount: 0, reloadTime: 0 }

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    let routerData: RouterStorageDataInterface = Storage.get(ROUTER_STORAGE_KEY) || { ...defaultReloadData }

    if (Date.now() - routerData.reloadTime > RELOAD_TIME_LIMIT) {
      routerData = Storage.set(ROUTER_STORAGE_KEY, { ...defaultReloadData })
    }

    if (routerData.reloadCount >= RELOAD_COUNT_LIMIT) {
      return
    }
    Storage.set(ROUTER_STORAGE_KEY, { reloadCount: routerData.reloadCount + 1, reloadTime: Date.now() })
    window.location.href = to.fullPath
  }
})

export default router
